<template>
  <div class="ricevimento">
    <h1>Ricevimento merci</h1>
    <p>Ciao</p>
  </div>
</template>

<script>
export default {
  name: "app",
  created:function() {
    localStorage.idmodule = '2'
  }
}
</script>